const binding = { modules: {}, dataActions: {} };

    (binding.modules['site-picker'] = {
        c: () => require('@msdyn365-commerce-modules/site-picker/dist/lib/modules/site-picker/site-picker'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'site-picker',
        p: 'site-picker',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/site-picker/dist/lib/modules/site-picker'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|vinhuset-adventureworks|views|site-picker'] = {
                c: () => require('partner/themes/vinhuset-adventureworks/views/site-picker.view.tsx'),
                cn: '__local__-__local__-site-picker'
            };
viewDictionary['@msdyn365-commerce-modules|site-picker|modules|site-picker|site-picker'] = {
                c: () => require('@msdyn365-commerce-modules/site-picker/dist/lib/modules/site-picker/site-picker.view.js'),
                cn: '@msdyn365-commerce-modules-site-picker-site-picker'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };